import { IHttpParams, IHttp, IHttpResponse } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IProduct } from 'types/DTO/products'
import { IBasketId, IBasketProduct } from 'types/DTO/basket'
import { object } from '~/mocks/products'

export interface IBasket {
  createBasket(id?: number): Promise<IHttpResponse<CommonResponse<IBasketId>>>

  createPrefilledBasket(params: IBasketProduct[]): Promise<any>

  findAll(id: number, params: IHttpParams): Promise<CommonResponse<any>>

  deleteBasketItem(
    id: number,
    product_id: number,
    pharmacy_id: number,
    params: IHttpParams,
  ): Promise<any>

  addToBasket(params: IBasketProduct): Promise<any>

  changeCount(
    id: number,
    product_id: number,
    pharmacy_id: number,
    params: IHttpParams,
  ): Promise<IHttpResponse<CommonResponse<IBasketProduct>>>

  deleteBasket(id: number): Promise<any>
  updateBasket(id: number, pharmacyId: number): Promise<any>
  concatBasket(id: number, params: IHttpParams): Promise<any>

  calculateAuthorizedCart(params: IHttpParams): Promise<any>
}

export default class Basket implements IBasket {
  constructor(private $http: IHttp) {}

  async createBasket(id?: number): Promise<IHttpResponse<CommonResponse<IBasketId>>> {
    // const formData = new FormData()
    // formData.append(`user_id`, `${id}`)
    // if (id) {
    //   //@ts-ignore
    //   return await this.$http.post('/api-v2/basket', new URLSearchParams(formData))
    // }
    return await this.$http.post('/api-v2/basket', {})
  }

  async createPrefilledBasket(params: IBasketProduct[]): Promise<any> {
    return await this.$http.post(`/api-v2/basket/prefilled`, { products: params })
  }

  async findAll(id: number, params: IHttpParams): Promise<CommonResponse<IProduct[]>> {
    return await this.$http.get(`/api-v2/basket/${id}`, params || {})
    // return await new Promise((resolve) => resolve(object.products))
  }

  async updateBasket(id: number, pharmacyId: number): Promise<any> {
    const formData = new FormData()
    formData.append(`pharmacy_id`, `${pharmacyId}`)
    const requestBody = pharmacyId
      ? //@ts-ignore
        new URLSearchParams(formData)
      : {}
    //@ts-ignore
    return await this.$http.put(`/api-v2/basket/${id}`, requestBody)
  }

  async deleteBasketItem(
    id: number,
    product_id: number,
    pharmacy_id: number,
    params: IHttpParams,
  ): Promise<any> {
    return await this.$http.delete(
      `/api-v2/basket/${id}/products/${product_id}/pharmacy/${pharmacy_id}`,
      params || {},
    )
    // return await new Promise((resolve) =>
    //   resolve(object.products.data.filter((item: any) => item.id !== product_id))
    // )
  }

  async deleteBasket(id: number): Promise<any> {
    return await this.$http.delete(`/api-v2/basket/${id}`)
  }

  async concatBasket(id: number, params: IHttpParams): Promise<any> {
    return await this.$http.get(`/api-v2/basket/concat/${id}`, params)
  }

  async addToBasket(params: IBasketProduct): Promise<any> {
    const formData = new FormData()
    for (const key in params) {
      formData.append(key, String(params[key as keyof IBasketProduct]))
    }
    return await this.$http.post(`/api-v2/basket/products`, formData)
  }

  async changeCount(
    id: number,
    product_id: number,
    pharmacy_id: number,
    params: IHttpParams,
  ): Promise<any> {
    return await this.$http.put(
      `/api-v2/basket/${id}/products/${product_id}/pharmacy/${pharmacy_id}?expand=basket`,
      params,
    )
  }

  async calculateAuthorizedCart(params: IHttpParams) {
    return await this.$http.get('/api-v2/basket/calculate-authorized-cart', params || {})
  }
}
