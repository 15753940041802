import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IHttpParams } from 'services/common/Http'
import { INewsCategory } from 'types/DTO/news'

export const state = () => ({
  articles: [],
  article: {},
  lastArticles: [],
  meta: 0,
  seo: {},
  categories: [] as INewsCategory[],
  newsPageSeo: {},
  newsPageSeoText: '',
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setArticles(state, payload) {
    state.articles = payload
  },
  setArticle(state, payload) {
    state.article = payload
  },
  setLastArticles(state, payload) {
    state.lastArticles = payload
  },
  setTotalPages(state, payload) {
    state.meta = payload
  },
  setSeo(state, payload) {
    state.seo = payload
  },
  setCategories(state, payload) {
    state.categories = payload
  },

  setNewsPageSeo(state, payload) {
    state.newsPageSeo = payload
  },

  setNewsPageSeoText(state, payload) {
    state.newsPageSeoText = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getNewsPage({ commit }) {
    try {
      const seoRes = await this.$repositories.content.findSeo('seo_blog')
      commit('setNewsPageSeo', seoRes?.data)
      commit('setNewsPageSeoText', seoRes?.data.text)
    } catch (e) {
      console.error('Error getting news page content', e)
    }
  },

  async getArticles({ commit, state }, { params, categoryId }) {
    try {
      const res = await this.$repositories.news.findAll(params, categoryId)
      const data = this.$services.news.adapt(res.data.data)
      commit('setArticles', data)
      commit('setTotalPages', res.data.meta)
    } catch (e) {
      console.log('getArticles error: ', e)
    }
  },
  async getLastArticles({ commit }, params: IHttpParams) {
    try {
      //@ts-ignore
      const res = await this.$repositories.news.findAll(params)
      const data = this.$services.news.adapt(res.data.data)
      commit('setLastArticles', data)
    } catch (error) {
      console.log('getLastArticles error: ', error)
    }
  },
  async getArticle({ commit }, articleData) {
    try {
      const res = await this.$repositories.news.findBySlug(articleData.slug, articleData.params)

      const data = this.$services.news.adapt(res.data)
      commit('setArticle', Object.assign({}, data))
      commit('setSeo', res.data.seo)
    } catch (e) {
      console.log('getArticle error: ', e)
    }
  },
  async getArticleCategories({ commit }, params) {
    try {
      const res = await this.$repositories.news.getCategories({})
      commit('setCategories', res.data.data)
    } catch (e) {
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Произошла проблема при загрузке категорий новостей',
      })
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  articles(state) {
    return state.articles
  },
  meta(state) {
    return state.meta
  },
  lastArticles(state) {
    return state.lastArticles
  },
  article(state) {
    return state.article
  },
  seo(state) {
    return state.seo
  },
  categories(state) {
    return state.categories
  },
  newsPageSeo(state) {
    return state.newsPageSeo
  },

  newsPageSeoText(state) {
    return state.newsPageSeoText
  },
}
