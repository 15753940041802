import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IHttpParams } from 'services/common/Http'
import { IContact, IContactDetails } from '~/types/DTO/contacts'

export const state = () => ({
	cities: [] as IContact[],
	allCities: [] as IContact[],
	details: [] as IContactDetails[],
	cityModal: false,
	currentCity: null as IContact | null,
	contactPageSeo: {},
	contactPageSeoText: '',
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
	setCity(state, payload) {
		state.cities = payload
	},

	setAllCities(state, payload) {
		state.allCities = payload
	},
	setDetails(state, payload) {
		state.details = payload
	},

	setCityModal(state, payload) {
		state.cityModal = payload
	},

	setCurrentCity(state, payload) {
		state.currentCity = payload
	},

	setContactPageSeo(state, payload) {
		state.contactPageSeo = payload
	},

	setContactPageSeoText(state, payload) {
		state.contactPageSeoText = payload
	},
}

export const actions: ActionTree<stateT, stateT> = {
	async getContactPage({ commit }) {
		try {
			const seoRes = await this.$repositories.content.findSeo('seo_contacts')
			commit('setContactPageSeo', seoRes?.data)
			commit('setContactPageSeoText', seoRes?.data.text)
		} catch (e) {
			console.error('Error getting main page content', e)
		}
	},

	async getCities({ commit }, { params, flag }) {
		try {
			const { data } = await this.$repositories.contacts.findAll({ params })
			const res = data.data
			if (flag === 'all') {
				commit('setAllCities', res)
			} else {
				commit('setCity', res)
			}
		} catch (e) {
			console.log('error in getCities', e)

			this.$services.toaster.add({
				type: 'error',
				title: '',
				description: 'Произошла ошибка при загрузке городов',
			})
		}
	},

	async getDetails({ commit }, { field, params }) {
		try {
			const data = await this.$repositories.contacts.findContactsDetails(field, params)
			/* TODO: убрать значения по умолчанию в сервисе, когда нужнен будет список */
			const res = this.$services.contacts.mapAddressDetails(data)

			commit('setDetails', res)
		} catch (e) {
			console.log('error in getDetails', e)
		}
	},

	async changeCity({ commit }, params: IHttpParams) {
		try {
			const { data } = await this.$repositories.contacts.setCity(params)
			const res = data

			// if (res.message === 'Город успешно изменен') {
			//   location.reload()
			// }
			// commit('setCity', res)
		} catch (e) {
			console.log('error in changeCity', e)

			this.$services.toaster.add({
				type: 'error',
				title: '',
				description: 'Произошла ошибка при выборе города',
			})
		}
	},
}

export const getters: GetterTree<stateT, stateT> = {
	cities(state) {
		return state.cities
	},
	allCities(state) {
		return state.allCities
	},
	details(state) {
		return state.details
	},

	contactPageSeo(state) {
		return state.contactPageSeo
	},

	contactPageSeoText(state) {
		return state.contactPageSeoText
	},
	cityModal(state) {
		return state.cityModal
	},

	currentCity(state) {
		return state.currentCity
	},
}
