import { Context } from '@nuxt/types'
import { IProduct, IProductEventGa } from 'types/DTO/products'

export default class ProductsEvent {
  constructor(
    private $store: any,
    private $services: Context['$services'],
  ) {}

  productViewPushEvent(params: any) {
    const eventProducts = params.products.map((product: IProduct) => {
      return {
        item_name: product?.title,
        item_id: product?.id,
        price: product?.prices?.standard?.price,
        item_brand: product?.producers?.title,
        item_category: product?.category?.title,
        availability: product?.prices?.standard?.count ?? 0 !== 0 ? 1 : 0,
        quantity: 1,
        ...params.itemListData,
      }
    })
    const eventItem = {
      ecommerce: {
        items: eventProducts,
      },
    }
    if (eventProducts.length) {
      this.$store.$gtm.push({ event: 'view_item', ...eventItem })
    }
  }
}
