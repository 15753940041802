import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IProducer } from 'types/DTO/producers/index'
import { IMetaData } from 'types/DTO/common/response'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  producers: [] as IProducer[],
  producerBySlug: {} as IProducer,
  producersPageSeo: {},
  producersPageSeoText: '',
  meta: {} as IMetaData,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setProducers(state, payload) {
    state.producers = payload
  },
  setProducerBySlug(state, payload) {
    state.producerBySlug = payload
  },
  setMeta(state, payload) {
    state.meta = payload
  },
  setProducersPageSeo(state, payload) {
    state.producersPageSeo = payload
  },
  setProducersPageSeoText(state, payload) {
    state.producersPageSeoText = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getProducers({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.producers.findAll(params)
      // @ts-ignore
      commit('setProducers', res.data.data)
      // @ts-ignore
      commit('setMeta', res.data.meta)
    } catch {
      console.log('error: getProducers')
    }
  },

  async getProducersPage({ commit }) {
    try {
      const seoRes = await this.$repositories.content.findSeo('seo_producers')
      commit('setProducersPageSeo', seoRes?.data)
      commit('setProducersPageSeoText', seoRes?.data.text)
    } catch (e) {
      console.error('Error getting main page content', e)
    }
  },

  async getProducerBySlug({ commit }, { slug, params }) {
    try {
      const res = await this.$repositories.producers.findBySlug(slug, params)
      commit('setProducerBySlug', res.data)
    } catch {
      console.log('error: getProducerBySlug')
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  producers(state) {
    return state.producers
  },
  producerBySlug(state) {
    return state.producerBySlug
  },
  producersPageSeo(state) {
    return state.producersPageSeo
  },
  producersPageSeoText(state) {
    return state.producersPageSeoText
  },
  meta(state) {
    return state.meta
  },
}
