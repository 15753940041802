import { IHttp, IHttpParams } from 'services/common/Http'

export default class PharmacyMaps {
  constructor(private http: IHttp) {}

  async getCartPharmacies(cartId: number, params: IHttpParams) {
    return await this.http.get(`api-v2/pharmacies/basket/m/${cartId}`, params)
  }
  async getpSpecificPharmaciesData(cartId: number, params: IHttpParams) {
    return await this.http.get(`api-v2/pharmacies/basket/${cartId}`, params)
  }
}
